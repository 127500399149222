/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import {
    shape, string, array,
} from 'prop-types';
// eslint-disable-next-line import/no-cycle
import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
// eslint-disable-next-line import/no-cycle
import SiteWideBannerContainer from '../../GraphqlHeader/SiteWideBanner/SiteWideBannerContainer';
import FallbackContentContainer from '../Common/FallbackContentContainer';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';

const PersonalizedSiteWideBannerContainer = ({
    pz_campaign_id,
    content_zones,
    fallbackUID,
    contentType,
    skeleton_height,
    skeleton_width,
    skeleton_margin = '0 auto',
}) => {
    const isBot = useSelector(getIsBot);

    if (isBot) {
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
            />
        );
    }

    if (typeof window === 'undefined') {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} id="PersonalizedSiteWideBannerContainer" />;
    }

    const interactionName = pz_campaign_id;

    const targeting = {
        pageType: 'home',
    };

    if (content_zones?.length && content_zones?.[0]) {
        targeting.contentZones = content_zones;
    }

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'PersonalizedContent',
        interactionName,
        targeting,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} />;
    }

    if (error) {
        mbpLogger.logError({
            function: `PersonalizedSiteWideBannerContainer - interactionName = ${interactionName}`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
            />
        );
    }

    if (data && data?.contentSF?.content) {
        const { content } = data?.contentSF;

        return (
            <div data-testid={content?.message}>
                <SiteWideBannerContainer bannerData={content} trackClickThrough={trackClickThrough} isFromPersonalizedSiteWideBanner />
            </div>
        );
    }
    return (
        <FallbackContentContainer
            skeleton_height={skeleton_height}
            skeleton_width={skeleton_width}
            fallbackUID={fallbackUID}
            contentType={contentType}
        />
    );
};

PersonalizedSiteWideBannerContainer.propTypes = {
    pz_campaign_id: string,
    content_zones: array,
    fallbackUID: string,
    contentType: string,
    skeleton_height: string,
    skeleton_width: string,
    skeleton_margin: string,
};

PersonalizedSiteWideBannerContainer.defaultProps = {
    pz_campaign_id: null,
    content_zones: [],
    fallbackUID: null,
    contentType: null,
    skeleton_height: null,
    skeleton_width: null,
    skeleton_margin: null,
};

export default PersonalizedSiteWideBannerContainer;
